@import 'styles/layers.scss';

@layer uikit {
  .wrap {
    display: inline-block;
    border: none;
    outline: none;
    margin: 0 0 10px;
    text-decoration: none;
    background: transparent;
    cursor: pointer;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-inline: 0;
    position: relative;
    font-weight: 300;
  }
  
  .dark {
    &.wrap {
      color: var(--white);
      &:hover, &:active {
        opacity: 1;
      }
      &:disabled {
        color: var(--black11);
      }
    }
  }
  
  .wrap::before {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    background-color: var(--white);
    left: 50%;
    bottom: -10px;
    transition: 0.3s all ease-in-out;
  }
  
  .underline {
    &.wrap[data-active*="true"]::before {
      width: 100%;
      left: 0;
    }
  }

  .dot {
    background-color: var(--yellow);
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-left: 4px;
    margin-bottom: 10px;
  }
}