@import 'styles/layers.scss';

@layer uikit {
  .wrap {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    column-gap: 25px;
  }
  
  .default {
    opacity: 0.5;
  }
  
  .active {
    opacity: 1;
  }
  
  .aligntLeft {
    padding-left: 0;
  }
}
