@import 'styles/layers.scss';

@layer uikit {
  .group {
    width: 100%;
  }
  
  .wrapper {
    position: relative;
    width: 100%;
  }

  .tab-wrapper {
    background-color: transparent;
    padding: 26px 0 0;
    width: 100%;
    &::before {
      content: "";
      position: absolute;
      height: 1px;
      width: 100%;
      background-color: var(--white);
      opacity: 0.1;
      top: 58px;
    }
  }
  
  .content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 30px 0;
    gap: 20px;
  }
}