@import 'styles/layers.scss';

@layer component {
  .table-container {
    min-width: 1250px;
    overflow-x: auto;
    height: fit-content;
    font-size: 14px;
    &-height {
      height: calc(100vh - 155px);
    }
  }
}