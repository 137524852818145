@import 'styles/layers.scss';

@layer component {
  .group {
    column-gap: 10px;
  }
  
  .dropdown {
    background-color: var(--black3);
    height: 34px;
    padding: 0 12px;
  }

  .dropdown-toggle {
    background-color: transparent;
  }
  
  .dropdown-menu {
    padding: 0;
    right: 0;
  }
}