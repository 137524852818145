@import 'styles/layers.scss';

@layer uikit {
  .wrap {
    padding: 20px;
    background-color: transparent;
    width: 100%;
    height: fit-content;
  }
  
  .content {
    row-gap: 20px;
  }
}